<script setup lang="ts"></script>

<template>
  <div class="loading-logo text-center relative">
    <img
      src="/images/logo-light-square.png"
      alt="Logo"
      class="h-[48px] block dark:hidden"
    />
    <img
      src="/images/logo-dark-square.png"
      alt="Logo"
      class="h-[48px] dark:block hidden"
    />
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<style lang="scss"></style>
